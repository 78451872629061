<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="api"/>
        <v-skeleton-loader 
            v-if="api.isLoading" type="table" ref="skeleton">

        </v-skeleton-loader>
        <v-row class="mt-3" v-else>
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="()=>{
                        $router.go(-1)
                    }"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card 
                    class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar 
                        class="secondary white--text pa-1">
                        <v-toolbar-title 
                            class="text-h5">
                            Demonstration Request
                        </v-toolbar-title>
                    </v-toolbar>
                    <div 
                        class="mt-5">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :readonly="readOnly"
                                    filled
                                    v-if="readOnly"
                                    label="First Name"
                                    dense
                                    outlined
                                    v-model="fname">
        
                                </v-text-field>
                                <v-text-field
                                    v-else
                                    :readonly="readOnly"
                                    label="First Name"
                                    dense
                                    outlined
                                    v-model="fname">
        
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    label="Last Name"
                                    :readonly="readOnly"
                                    dense
                                    filled
                                    outlined
                                    v-if="readOnly"
                                    v-model="lname">
        
                                </v-text-field>
                                <v-text-field
                                    label="Last Name"
                                    v-else
                                    dense
                                    outlined
                                    v-model="lname">
        
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <div 
                            class="d-flex justify-content-start">
                            <v-text-field
                                v-if="readOnly"
                                outlined 
                                filled
                                :readonly="readOnly"
                                v-model="email"
                                label="Email"
                                type="email">
                            </v-text-field>
                            <v-text-field
                                v-else
                                dense 
                                outlined 
                                v-model="email"
                                label="Email"
                                type="email">
                            </v-text-field>
                        </div>
                        <div 
                            class="d-flex justify-content-start">
                            <v-text-field
                                v-if="readOnly"
                                filled
                                outlined
                                :readonly="readOnly"
                                v-model="mobile"
                                label="Mobile">
                            </v-text-field>
                            <v-text-field
                                v-else
                                dense
                                outlined
                                v-model="mobile"
                                label="Mobile">
                            </v-text-field>
                        </div>
                        <div
                            class="d-flex justify-content-start">
                            <v-text-field
                                dense
                                v-if="readOnly"
                                filled 
                                outlined 
                                :readonly="readOnly"
                                v-model="company"
                                label="Company">
                            </v-text-field>
                            <v-text-field
                                v-else
                                dense 
                                outlined 
                                :readonly="readOnly"
                                v-model="company"
                                label="Company">
                            </v-text-field>
                        </div>
                        <div
                            class="d-flex justify-content-start">
                            <v-select
                                v-model="status"
                                :items = "statusOptions"
                                label="Status"
                                dense 
                                outlined>
                            </v-select>
                        </div>
                        <div
                            class="d-flex justify-content-start">
                            <v-autocomplete
                                :readonly="readOnly"
                                v-if="staff!=null && !readOnly"
                                v-model="pic"
                                :items="staff"
                                item-text="email"
                                item-value="user_id"
                                dense
                                label="Please choose the PIC"
                                return-object
                                outlined >

                            </v-autocomplete>
                        </div>
                        <div 
                            class="d-flex justify-content-start">
                            <v-textarea
                            v-model="remark"
                            solo
                            :counter="500"
                            :rules="rulesMaxLength"
                            label="Please insert your remark">

                            </v-textarea>
                        </div>
                        <div 
                            class="d-flex justify-end">
                            <v-btn 
                                color="primary" 
                                plain
                                @click="()=>{
                                    $router.go(-1);
                                }">
                                Cancel
                            </v-btn>
                            <v-btn
                                color="primary" 
                                :loading="api.isLoading"
                                @click="validateInput">
                                Confirm
                            </v-btn>
                        </div>
                    </div>


                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        rulesMaxLength:[v=>v.length<=500||'Max 500 Characters'],
        fname:null,
        staff:[],
        lname:null,
        readOnly:true,
        email:null,
        mobile:null,
        company:null,
        status:null,
        pic:null,
        remark:null,
        allowedRolesEdit:['developer','vision_admin','operation'],
        isPending:false,
        statusOptions : ['Pending',"Scheduled","Completed","Cancelled"],
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getDemonstration") 
            {
                let demonstration = resp.data.demonstration;
                this.fname = demonstration.fname;
                this.lname = demonstration.lname;
                this.email = demonstration.email;
                this.mobile = demonstration.mobile;
                this.company = demonstration.company;
                this.status = demonstration.status;
                this.remark = demonstration.remark;
                this.pic = demonstration.pic_id;
                this.staff = resp.data.staff;
            }
            if(resp.class==="updateDemonstration") {
                this.api.isSuccesful = true;
                this.api.success = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            if(this.allowedRolesEdit.some(role=>this.$store.getters.getRole.includes(role))){
                this.readOnly = false;
            }
            let fetchDemonstrationApi = this.fetchDemonstration();
            this.$api.fetch(fetchDemonstrationApi);
        },
        fetchDemonstration(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/demonstration/"+this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let createApi = this.create();
            this.$api.fetch(createApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/demonstration/"+this.$router.history.current.params.id;
            tempApi.params = {
                'fname':this.fname,
                "lname":this.lname,
                "email":this.email,
                "mobile":this.mobile,
                "company":this.company,
                'status':this.status,
                "remark":this.remark,
                'pic_id':(this.pic!=null)?this.pic.user_id:null,
            };
            return tempApi;
        },
    }
}
</script>